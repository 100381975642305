
// use global jquery which already setup datatables

var currency_format = $.fn.dataTable.render.number(',','.',2);

window.ttu_dt_col_renders = {
  dateThaiLastestBuy: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    // ignore blank string
    if (!data || !data.trim())
      return 'เกิน 1 ปี';
    var data_date = moment(data);
    if (data_date.isValid())
      return data_date.locale('th').format('DD MMM tYYYY');
    return '';
  },
  dateThai: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    // ignore blank string
    if (!data || !data.trim())
      return '';
    var data_date = moment(data);
    if (data_date.isValid())
      return data_date.locale('th').format('DD MMM tYYYY');
    return '';
  },
  monthYearThai: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    // ignore blank string
    if (!data || !data.trim())
      return '';
    var data_date = moment(data);
    if (data_date.isValid())
      return data_date.locale('th').format('MMM tYYYY');
    return '';
  },
  dateTimeThai: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    // ignore blank string
    if (!data || !data.trim())
      return '';
    var data_date = moment(data);
    if (data_date.isValid())
      return data_date.locale('th').format('DD MMM tYYYY hh:mm:ss');
    return '';
  },
  numCurrency: currency_format,
  numTotal: (data, type, row, meta) => {
    var qty = data.Qty || data.qty || 0;
    var price = data.PricePerUnit || data.Price || data.price || 0;
    var discount = data.DiscountPerUnit || data.Discount || 0;
    return currency_format.display(qty * (price - discount))
  },
  textBool: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    if (['1', 1, 'yes', 'true', true, 'ใช่'].includes(data))
      return "<span class='badge badge-pill badge-success'>ใช่</span>";
    return "<span class='badge badge-pill badge-danger'>ไม่ใช่</span>";
  },
  textBoolReverse: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    if (['1', 1, 'yes', 'true', true, 'ใช่'].includes(data))
      return "<span class='badge badge-pill badge-danger'>ไม่ใช่</span>";
    return "<span class='badge badge-pill badge-success'>ใช่</span>";
  },
  textPayment: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    switch(data) {
      case -1: return 'หลายประเภท';
      case 0: return 'เงินสด';
      case 1: return 'เครดิต';
      case 2: return 'บัตรเครดิต';
      case 3: return 'บัตรเงินผ่อน';
      case 4: return 'เช็ค';
      case 5: return 'บัตรเครดิตผ่อน 0%';
      case 6: return 'โอน';
      default: return '';
    }
  },
  doc_type: (data, type, row, meta) => {
    // only use in those render type
    if (!['display', 'filter'].includes(type))
      return data;
    switch(data) {
      case 0: return 'การรับสินค้า';
      case 1: return 'ใบรายการซ่อม';
      case 2: return 'ใบกำกับภาษี';
      default: return '';
    }
  },
};

global.ttu_generic_column_filter = (table,tableHead) => {

  // clone header column and remove sorting indicator of the cloned column
  $('thead tr', tableHead).clone(false).appendTo($('thead', tableHead));
  $('thead tr:eq(1) th', tableHead).each ( (i, x) => {
    $(x)
      .removeClass('sorting sorting_asc sorting_desc') // remove sorting arrow icon
      .attr('style', 'padding: 2px;') // make input box larger
      .html('')
  });

  table.columns().flatten().each( function ( colIdx ) {
    // Create the select list and search operation
    var th1 = table.column(colIdx).header()
    var th2 = $(th1).parent().next().find('th').eq($(th1).index())
    $('<input type="text" class="form-control form-control-sm" placeholder=""  data-col-name="'+table.column(colIdx).dataSrc()+'"/>')
      .val(table.column(colIdx).search())
      .appendTo(th2)
      .on( 'keyup change', debounce( function (e) {
        var searchKey = e.currentTarget.value
        var col_name = $(e.currentTarget).data('colName')

        // on server side, when regex is true (the second params), we will just search use "LIKE %searchKey%"
        // but when it is false, will do exact match
        oldKey = table.column( col_name+':name'  ).search()
        if (oldKey != searchKey) {
          table.column( col_name+':name'  ).search( searchKey, true ).draw();

          //console.log('old ' + oldKey)
          //console.log('new ' + searchKey)
        }
      },500 ) )
  } );
  table.columns.adjust().draw()
}

var init_single_datatable = (table, table_option = null, editor_option = null) => {

  // table configuration
  var searchDelay = 400;

  var defaultTableOption = {
    //dom: "<'row'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-7'i><'col-sm-12 col-md-5'p>>",
    dom: "<'row'<'col-sm-12 col-md-9'B><'col-sm-12 col-md-3'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-6'i><'col-sm-12 col-md-6 dt-footer-position'>>",
    language: {url: '/datatable.i18n.thai.custom.json'},
    pageLength: 25,
    processing: true,
    //serverSide: true,
    scrollY: '50vh',
    scrollCollapse: true,
    scroller: true,
    deferRender: true,
    select: true,
    searchDelay: searchDelay,
    buttons: {
      dom: {
        button: {
          className: 'btn'
        },
        container: {
          className: ''
        },
      },
      buttons: [ // built-in buttons: print, pdf, csv, excel, copy
        //{
        //  extend: 'excel',
        //  className: 'btn-dark',
        //},
        //{
        //  extend: 'copy',
        //  className: 'btn-dark',
        //}
      ]
    },
  };

  var $table = $(table);

  // apply bootstrap classes styling
  $table.addClass('dataTable table table-sm table-striped table-bordered table-hover');

  // add footer children if footer tag is presented
  if ($table.find('tfoot').length > 0) {
    var tfoot = $table.find('tfoot')
    // add tr if missing
    if (tfoot.find('tr').length == 0) {
      tfoot.append('<tr></tr')
    }
    var tr = tfoot.find('tr')

    //create template td
    td_html = '<td></td>'
    if (tr.find('td').length > 0) {
      td_html = tr.find('td').first().html()
    }

    while (tr.find('td').length < table_option.columns.length) {
      tr.append(td_html)
    }
  }



  // enable server side processing if 'query-url' is available
  var query_url = $table.data('query-url');
  if (query_url) {
    defaultTableOption.serverSide = true;
    defaultTableOption.ajax = {
      url: query_url,
      type: 'POST',
      dataType: 'json',
      beforeSend: (request) => {
          request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      // handle url redirection when user session expire
      error: ttu_bootbox_handle_ajax_error,
    };
  }

  var permissions = $table.data('perm') || [];

  // add new button if 'new-url' is available
  var new_url = $table.data('new-url');
  if (new_url && permissions.includes('create')) {
    defaultTableOption.buttons.buttons.push({
      text: 'เพิ่ม', className: 'btn-success',
      action: function ( e, dt, node, config ) {
        window.location.assign(new_url);
      }
    });
  }

  // add show button
  var has_show_url = $table.data('has-show-url');
  if (has_show_url && permissions.includes('show')) {
    defaultTableOption.buttons.buttons.push({
      text: 'แสดง',
      extend: 'selected',
      className: 'btn-info',
      action: function ( e, dt, node, config ) {
        var row = dt.row( { selected: true } );
        if (row) {
          var url = row.data().show_url;
          if (url)
            window.location.assign(url);
        }
      }
    });
  }

  // add edit button
  var has_edit_url = $table.data('has-edit-url');
  if (has_edit_url && permissions.includes('edit')) {
    defaultTableOption.buttons.buttons.push({
      text: 'แก้ไข',
      extend: 'selected',
      className: 'btn-primary',
      action: function ( e, dt, node, config ) {
        var row = dt.row( { selected: true } );
        if (row.node()) {
          var url = row.data().edit_url;
          if (url)
            window.location.assign(url);
        }
      }
    });
  }

  // add remove button
  var has_remove_url = $table.data('has-remove-url');
  if (has_remove_url && permissions.includes('destroy')) {
    defaultTableOption.buttons.buttons.push({
      text: 'ลบ',
      extend: 'selected',
      className: 'btn-warning',
      action: function ( e, dt, node, config ) {
        var row = dt.row( { selected: true } );
        if (row.node()) {
          var label = $('td.label:first', row.node()).text() || $('td:first', row.node()).text();
          ttu_show_bootbox_confirm_delete(
            label,
            row.data().remove_url,
            () => { 
              if (dt.page.info().serverSide) {
                dt.draw(false);
              } else {
                dt.ajax.reload();
              }
            }
          );
        }
      }
    });
  }

  // add column filter
  var has_column_filter = $table.data('has-column-filter');
  defaultTableOption.initComplete = () => {
    //to DRY this, I should call ttu_generic_column_filter, but I didn't

    // get DataTable instance
    var dt = $table.DataTable();
    // find thead in another <table> due to scroller option
    var $tableHead = $table.closest('.dataTables_scroll').find('.dataTables_scrollHeadInner table')[0];
    // make column header DARK!!
    $('thead', $tableHead).addClass('thead-dark');
    // adjust min height
    //$table.closest('.dataTables_scrollBody').css('min-height', '50vh');

    if (has_column_filter) {
      //clone header row
      $('thead tr', $tableHead).clone(false).appendTo($('thead', $tableHead));
      //iterate each cell in the cloned header row
      $('thead tr:eq(1) th', $tableHead).each ( (i, x) => {
        //make html of input element
        $(x)
          .removeClass('sorting sorting_asc sorting_desc') // remove sorting arrow icon
          .attr('style', 'padding: 2px;') // make input box larger
          .html( '<input value="'+dt.column(i).search()+'"type="text" class="form-control form-control-sm" data-col-name="'+dt.column(i).dataSrc()+'"/>' );

        //hook the on change to fire search query
        $( 'input', x ).on( 'keyup change', debounce((e) => {
          var searchKey = e.currentTarget.value;
          var col_name = $(e.currentTarget).data('colName')
          var col = dt.column( col_name+':name'  )
          if ( col.search() !== searchKey ) {
            col.search( searchKey,true ).draw(false)
          }
        }, searchDelay ) );
      } );
    }
    dt.columns.adjust();
  };

  // add columns data defined in table
  var columns = $table.data('columns');
  if (columns) {
    defaultTableOption.columns = columns;
  }

  if (table_option === null) {
    // nothing to do here...
  } else if (typeof table_option === 'function') {
    // apply table_option function
    defaultTableOption = table_option(defaultTableOption);
  } else if (typeof table_option === 'object') {
    // merge table_option object
    var ignored = {};
    if (table_option.buttons && table_option.buttons.constructor === Array) {
      // push buttons instead of replacing it
      defaultTableOption.buttons.buttons.push(...table_option.buttons);
      ignored.buttons = table_option.buttons;
      delete table_option.buttons;
    }
    if (defaultTableOption.columns && table_option.columns && table_option.columns.constructor === Array) {
      // push columns instead of replacing it
      defaultTableOption.columns.push(...table_option.columns);
      ignored.columns = table_option.columns;
      delete table_option.columns;
    }
    if (defaultTableOption.ajax && table_option.ajax) {
      // nested object merge
      Object.assign(defaultTableOption.ajax, table_option.ajax);
      ignored.ajax = table_option.ajax;
      delete table_option.ajax;
    }
    // TODO: do more elaborate merge?
    Object.assign(defaultTableOption, table_option);
    // restore ignored properties since we should not alter table_option
    Object.assign(table_option, ignored);
  }

  // editor configuration

  var useEditor = false;
  var defaultEditorOption = {
    table: $table,
  };

  var editor_url = $table.data('editor-url');
  if (editor_url) {
    defaultEditorOption.ajax = {
      url: editor_url,
      method: 'PUT',
      beforeSend: (request) => {
          request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      error: ttu_bootbox_handle_ajax_error,
    };
    useEditor = true;
  }

  var fields = $table.data('fields');
  if (fields) {
    defaultEditorOption.fields = fields;
    useEditor = true;
  }

  if (editor_option === null) {
    // nothing to do here...
  } else if (typeof editor_option === 'function') {
    // apply editor_option function
    defaultEditorOption = editor_option(defaultEditorOption);
    useEditor = true;
  } else if (typeof editor_option === 'object') {
    // merge editor_option object
    var ignored = {};
    if (defaultEditorOption.fields && editor_option.fields && editor_option.fields.constructor === Array) {
      // push fields instead of replacing it
      defaultEditorOption.fields.push(...editor_option.fields);
      ignored.fields = editor_option.fields;
      delete editor_option.fields;
    }
    if (defaultEditorOption.ajax && editor_option.ajax) {
      // nested object merge
      Object.assign(defaultEditorOption.ajax, editor_option.ajax);
      ignored.ajax = editor_option.ajax;
      delete editor_option.ajax;
    }
    // TODO: do more elaborate merge?
    Object.assign(defaultEditorOption, editor_option);
    // restore ignored properties since we should not alter editor_option
    Object.assign(editor_option, ignored);
    useEditor = true;
  }

  var editor = null;

  if (useEditor === true) {
    editor = new $.fn.dataTable.Editor(defaultEditorOption);

    // Activate an inline edit on click of a table cell with editable class
    $table.on( 'click', 'tbody td.editable', function (e) {
      editor.inline( e.currentTarget, { onBlur: 'submit' } );
    } );
  }

  return {
    dt: $table.DataTable(defaultTableOption),
    editor: editor,
  };
};

//this return array of { dt:, editor: }
global.ttu_init_datatable = (tables, table_option = null, editor_option = null) => {
  //$(tables).each((i, e) => {init_single_datatable(e, table_option, editor_option)});
  return $(tables).map((i, e) => { return init_single_datatable(e, table_option, editor_option); }).get();
};



